import { getCurrentInstance } from 'vue';

export const useI18n = () => {
  const instance = getCurrentInstance();

  const getCurrentLang = localStorage.getItem('lang');

  const executeStringI18nQuery = (cmd: string) => {
    try {
      return instance?.appContext.config.globalProperties.$i18n(cmd) || cmd;
    } catch (e) {
      return cmd;
    }
  };

  return {
    $i18n: instance?.appContext.config.globalProperties.$i18n,
    lang: getCurrentLang,
    executeStringI18nQuery,
  };
};
