import en from '../en.json';
import cn from '../cn.json';
import es from '../es.json';
import type { LangKey } from '@gem/element-setting-ui';

export default {
  lang: localStorage.getItem('lang') || 'en',
  load: () => {
    const translated: Partial<Record<LangKey, any>> = {
      en,
      cn,
      es,
    };

    return translated[(localStorage.getItem('lang') || 'en') as LangKey] ?? {};
  },
  install: (app: any, options: Record<string, any>) => {
    app.config.globalProperties.$i18n = (key: string) => {
      return key.split('.').reduce((o, i) => {
        if (o) return o[i];
      }, options);
    };
  },
};
